import React from 'react';

import { graphql } from 'gatsby';

import DefaultLayout from '../layouts';
import ContentSection from '../layouts/content-section';
import WordpressContent from '../components/wordpress-content';
import Metadata from '../components/metadata';

const PageTemplate = ({ data, location }) => {
  const currentPage = data.wpPage;

  return (
    <DefaultLayout>
      <Metadata page={currentPage} location={location} />

      <ContentSection>
        <h1>{currentPage.title}</h1>

        <WordpressContent>{currentPage.content}</WordpressContent>
      </ContentSection>
    </DefaultLayout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
    }
  }
`;
