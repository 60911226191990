import React from 'react';

import * as sectionStyles from './content-section.module.css';

const ContentSection = ({ children, dark }) => {
  const className = dark
    ? sectionStyles.contentSectionDark
    : sectionStyles.contentSection;

  return (
    <section className={className}>
      <div className={sectionStyles.contentSectionInner}>{children}</div>
    </section>
  );
};

export default ContentSection;
