import React from 'react';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';

import Link from './link';

const transformLink = (node, index) => {
  if (node.type === 'tag' && node.name === 'a') {
    const { href, ...otherAttrs } = node.attribs;
    return (
      <Link to={href} {...otherAttrs}>
        {node.children.map((child, i) => convertNodeToElement(child, i))}
      </Link>
    );
  }
};

const WordpressContent = ({ children }) => {
  return (
    <React.Fragment>
      {ReactHtmlParser(children, {
        transform: transformLink,
      })}
    </React.Fragment>
  );
};

export default WordpressContent;
